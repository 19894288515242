<template>
  <div>
    <van-field
      class="input"
      readonly
      v-model="datetimeVal"
      icon-prefix="chame"
      right-icon="date"
      @click="isShow = true"
    />
    <van-popup v-model:show="isShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        @confirm="onConfirm"
        @cancel="isShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {formatDate} from '@/utils'
export default {
  name: 'MyDateTimePicker',
  data() {
    return {
      isShow: false,
      minDate: new Date(),
      currentDate: new Date(),
      datetimeVal: ''
    }
  },
  created() {
    this.datetimeVal = formatDate(this.currentDate)
  },
  methods: {
    onConfirm(event) {
      this.isShow = false
      this.currentDate = event
      this.datetimeVal = formatDate(this.currentDate)
      this.$emit('confirm', this.datetimeVal)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .input {
  height: 50px;
  padding: 0 10px;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 24px;
}
</style>
