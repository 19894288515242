<template>
  <!-- 物品进货申请 -->
  <div>
    <div class="title">申请信息：</div>
    <van-row class="row" gutter="10">
      <van-col class="row-col" span="24">
        <span class="label">负 责 人:</span>
        <van-field class="input" v-model="name" />
      </van-col>
      <van-col class="row-col" span="24" style="margin-top: 10px;">
        <span class="label">联系电话:</span>
        <van-field class="input" type="tel" maxlength="11" v-model="mobile" />
      </van-col>
    </van-row>
    <van-row class="row" gutter="10">
      <van-col class="row-col" span="24">
        <span class="label">车辆类型:</span>
        <van-field class="input" v-model="car_type" />
        <i>(非必填)</i>
      </van-col>
      <van-col class="row-col" span="24" style="margin-top: 10px;">
        <span class="label">车 牌 号:</span>
        <van-field class="input" maxlength="7" v-model="car_no" />
        <i>(非必填)</i>
      </van-col>
    </van-row>
    <van-row class="row" gutter="10">
      <van-col class="row-col" span="24">
        <span class="label">进货日期:</span>
        <div style="flex: 1;">
          <date-time-picker ref="myDateRef" @confirm="selectedDate" />
        </div>
      </van-col>
      <van-col class="row-col" span="24" style="margin-top: 10px;">
        <span class="label">进货时间:</span>
        <van-field class="input" readonly is-link v-model="time" @click="isShow = true" />
      </van-col>
    </van-row>
    <div class="remark-title">备注 <i style="color: red;">(针对走特殊1楼线路的租户):</i></div>
    <div class="remark-val">
      <van-field class="textarea" autosize type="textarea" v-model="remark"  />
    </div>

    <div class="title">商品信息：</div>
    <div class="tab-row">
      <span>商品名</span>
      <span>规格</span>
      <span>数量</span>
    </div>
    <div class="tab-row" v-for="(item, index) in goods" :key="index">
      <van-field class="input" readonly v-model="item.goods_name" />
      <van-field class="input" readonly v-model="item.goods_rule" />
      <van-field class="input" readonly v-model="item.goods_num" />
      <van-icon class="icon" name="minus" color="#ee0a24" @click="delGoodsInfo(index)" />
    </div>
    <div class="tab-row">
      <van-field class="input" v-model="goods_name" @blur="onBlue" />
      <van-field class="input" v-model="goods_rule" @blur="onBlue" />
      <van-field class="input" type="digit" v-model="goods_num" @blur="onBlue" />
      <van-icon class="icon" name="plus" color="#1989fa" @click="addGoodsInfo" />
    </div>
<!--    <van-notice-bar-->
<!--      style="margin-top: 10px;"-->
<!--      left-icon="volume-o"-->
<!--      scrollable-->
<!--      text="如选择电器类商品则需申请施工单"-->
<!--    />-->
    <div class="notice-title">进离场注意事项：</div>
    <ul class="notice-content">
      <ol>1、进离场物品名称、数量需与清单一致，否则不予出场。</ol>
      <ol>2、出场物品核对无误后，准许出场。</ol>
      <ol>3、施工物品请于晚上10点后安排进出。</ol>
      <ol>4、运输建筑材料、垃圾及物品，途中公区地面必须做好保护，不得造成污染。</ol>
      <ol>5、车辆运输进货物进出场不得将公区设施设备损坏，损坏必须造价赔偿。</ol>
      <ol>6、广场手扶电梯及客梯严禁运货使用，损坏的必须造价赔偿。</ol>
    </ul>

    <div class="btns">
      <van-button type="primary" size="small" block round @click="onSubmit">提交申请</van-button>
    </div>

     <!-- 进货时间选择 -->
    <van-action-sheet v-model:show="isShow" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import { formatDate } from '@/utils'
import { postInGoodsApi } from '@/api'
import DateTimePicker from '@/components/picker/datetime.vue'
export default {
  name: 'LicenseInGoods',
  components: {
    DateTimePicker
  },
  data() {
    return {
      sh_info: {}, // 商户信息
      isShow: false,
      actions: [{ name: '14:00-16:00' }, { name: '22:00-10:00' }, {name: '其他'}],
      name: '', // 负责人姓名
      mobile: '', // 负责人手机
      car_type: '', // 车辆类型
      car_no: '', // 车牌号
      date: '', // 进货日期
      time: '', // 进货时间
      goods_type: '',
      goods: [],
      goods_name: '', // 商品名
      goods_rule: '', // 规格
      goods_num: '', // 数量
      remark: '', // 备注
    }
  },
  created() {
    if (!window.localStorage.getItem('sh_info')) return
    this.sh_info = JSON.parse(window.localStorage.getItem('sh_info'))
  },
  methods: {
    // 进货日期选择
    selectedDate(date) {
      this.date = date
    },
    onSelect(item) {
      this.time = item.name
      this.isShow = false
    },
    // 商品信息 失焦事件
    onBlue() {
      if (!this.goods_name || !this.goods_rule || !this.goods_num) return
      this.addGoodsInfo()
    },
    // 添加商品信息
    addGoodsInfo() {
      if (!this.goods_name) return this.$toast('商品名称不能为空！')
      if (!this.goods_rule) return this.$toast('商品规格不能为空！')
      if (!this.goods_num) return this.$toast('商品数量不能为空！')
      this.goods.push({
        goods_name: this.goods_name,
        goods_rule: this.goods_rule,
        goods_num: this.goods_num,
      })
      this.goods_name = ''
      this.goods_rule = ''
      this.goods_num = ''
    },
    delGoodsInfo(index) {
      this.goods.splice(index, 1)
    },
    // 提交
    onSubmit() {
      if (
        !this.name ||
        !this.mobile ||
        !this.time ||
        !this.goods.length
      )
        return this.$toast('请补全信息！')

      const params = {
        sid: this.sh_info.sid,
        encrypt: this.sh_info.encrypt,
        name: this.name,
        mobile: this.mobile,
        car_type: this.car_type,
        car_no: this.car_no,
        date: this.date || formatDate(new Date()),
        time: this.time,
        goods: this.goods,
        remark: this.remark
      }

      postInGoodsApi(params).then((res) => {
        console.log('进货申请单：', res)
        if (res.code !== 0) return
        this.$toast({
          type: 'success',
          message: '提交成功！',
          onClose: () => {
            this.$router.push('/license/list')
          }
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 40px;
  font-size: 32px;
  font-weight: 500;
}
/deep/ .row {
  margin-top: 20px;
  .row-col {
    height: 50px;
    display: flex;
    .label {
      line-height: 50px;
      width: 200px;
      font-size: 26px;
      color: #666;
      white-space: nowrap;
    }
    .input {
      width: 300px;
      height: 50px;
      padding: 0 10px;
      border: 1px solid #eee;
      border-radius: 8px;
      font-size: 24px;
    }
    & > i {
      line-height: 50px;
      font-size: 24px;
      padding: 0 10px;
      color: red;
    }
  }
}

/deep/ .select {
  margin-top: 20px;
}

.tab-row {
  margin-top: 20px;
  padding-right: 50px;
  display: flex;
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 32px;
  }
  span {
    flex: 1;
    text-align: center;
    font-size: 26px;
    color: #333;
  }
  /deep/ .input {
    height: 50px;
    padding: 0 10px;
    border: 1px solid #eee;
    border-radius: 8px;
    font-size: 24px;
    &:nth-of-type(2) {
      margin: 0 20px;
    }
  }
}

.notice-title {
  font-size: 32px;
  font-weight: 500;
  margin-top: 30px;
}
.notice-content {
  margin-top: 10px;
  ol {
    font-size: 24px;
    color: #999;
  }
}

.btns {
  margin: 80px 150px 40px;
}

.remark-title {
  margin-top: 20px;
  font-size: 26px;
  color: #666;
}
.remark-val {
  /deep/ .textarea {
    margin-top: 10px;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 5px 10px;
  }
}
</style>
